import { createContext, useState, useEffect } from 'react';
import { jwtDecode } from 'jwt-decode';
import { impersonateUser } from '../api/userApi';
import { useNavigate } from 'react-router-dom';

const AuthContext = createContext();

export default AuthContext;

export const AuthProvider = ({ children }) => {
  let [user, setUser] = useState(() =>
    localStorage.getItem('authTokens') ? jwtDecode(localStorage.getItem('authTokens')) : null
  );
  let [authTokens, setAuthTokens] = useState(() =>
    localStorage.getItem('authTokens') ? JSON.parse(localStorage.getItem('authTokens')) : null
  );
  let [loading, setLoading] = useState(true);
  let [isImpersonating, setIsImpersonating] = useState(() => 
    !!localStorage.getItem('originalAuthTokens')
  );

  const navigate = useNavigate();

  let loginUser = async e => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_HOST}/api/token/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          username: e.target.username.value,
          password: e.target.password.value,
        }),
      });
      
      if (response.status === 200) {
        let data = await response.json();
        if (data) {
          localStorage.setItem('authTokens', JSON.stringify(data));
          setAuthTokens(data);
          setUser(jwtDecode(data.access));
          navigate('/');
        } else {
          throw new Error('Something went wrong during login.');
        }
      } else {
        let errorData = await response.json();
        if (errorData.detail === 'No active account found with the given credentials') {
          throw new Error('No account found with given credentials.');
        } else {
          throw new Error('Error during Login');
        }
      }
    } catch (error) {
      throw error;
    }
  };

  let loginGoogleUser = async (data) => {
    try {
      localStorage.setItem('authTokens', JSON.stringify(data));
      setAuthTokens(data);
      setUser(jwtDecode(data.access));
      navigate('/');
    } catch (error) {
      throw error;
    }
  }

  let logoutUser = () => {
    localStorage.removeItem('authTokens');
    localStorage.removeItem('originalAuthTokens');
    setAuthTokens(null);
    setUser(null);
    setIsImpersonating(false);
    navigate('/login');
  };

  const impersonateLogin = async (userId) => {
    try {
      if (!user?.is_staff) {
        throw new Error('Non autorizzato');
      }

      
      if (!isImpersonating) {
        localStorage.setItem('originalAuthTokens', localStorage.getItem('authTokens'));
      }

      const data = await impersonateUser(userId, authTokens.access);
      
      localStorage.setItem('authTokens', JSON.stringify(data));
      setAuthTokens(data);
      setUser(jwtDecode(data.access));
      setIsImpersonating(true);
      navigate('/');
    } catch (error) {
      console.error('Error during impersonation', error);
      throw error;
    }
  };

  const endImpersonation = () => {
    try {
      const originalTokens = localStorage.getItem('originalAuthTokens');
      if (originalTokens) {
        const tokens = JSON.parse(originalTokens);
        
        localStorage.setItem('authTokens', originalTokens);
        localStorage.removeItem('originalAuthTokens');
        
        setAuthTokens(tokens);
        setUser(jwtDecode(tokens.access));
        setIsImpersonating(false);
        
        navigate('/');
      }
    } catch (error) {
      console.error('Error during admin account recover', error);
      logoutUser();
    }
  };

  const updateToken = async () => {
    try {
      if (!authTokens?.refresh) {
        setLoading(false);
        return;
      }

      const response = await fetch(`${process.env.REACT_APP_API_HOST}/api/token/refresh/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ refresh: authTokens.refresh }),
      });

      const data = await response.json();
      if (response.status === 200) {
        setAuthTokens(data);
        setUser(jwtDecode(data.access));
        localStorage.setItem('authTokens', JSON.stringify(data));
      } else {
        logoutUser();
      }
    } catch (error) {
      console.error('Errore during token update', error);
      logoutUser();
    } finally {
      setLoading(false);
    }
  };

  let contextData = {
    user: user,
    authTokens: authTokens,
    loginUser: loginUser,
    logoutUser: logoutUser,
    loginGoogleUser: loginGoogleUser,
    impersonateLogin: impersonateLogin,
    endImpersonation: endImpersonation,
    isImpersonating: isImpersonating
  };

  useEffect(() => {
    const REFRESH_INTERVAL = 1000 * 60 * 4; // 4 minutes
    
    if (loading && authTokens?.refresh) {
      updateToken();
    }

    let interval = setInterval(() => {
      if (authTokens?.refresh) {
        updateToken();
      }
    }, REFRESH_INTERVAL);
    
    if (!authTokens?.refresh) {
      setLoading(false);
    }
    
    return () => clearInterval(interval);
  }, [authTokens, loading]);

  return (
    <AuthContext.Provider value={contextData}>
      {!loading && children}
    </AuthContext.Provider>
  );
};
