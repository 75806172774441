import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import React, { useState, useEffect, useContext } from 'react';
import { AuthProvider } from './context/AuthContext';
import LoginPage from './pages/LoginPage';
import Header from './components/Header';
import PrivateRoute from './utils/PrivateRoute';
import AuthContext from './context/AuthContext';
import { getProfile } from './api/userApi';
import StudentHomePage from './pages/student/HomePage';
import TeacherHomePage from './pages/teacher/HomePage';
import HomePage from './pages/HomePage';
import ProcessPage from './pages/ProcessPage';
import { Navigate, useLocation } from 'react-router-dom';


function AppRoutes() {
  const { authTokens, logoutUser, user } = useContext(AuthContext);
  const [profile, setProfile] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchProfile = async () => {
      if (!authTokens?.access) {
        setIsLoading(false);
        return;
      }

      try {
        const profileData = await getProfile(authTokens.access);
        setProfile(profileData);
      } catch (error) {
        console.error('Errore nel recupero del profilo:', error);
        if (error.response?.status === 401) {
          logoutUser();
        }
      } finally {
        setIsLoading(false);
      }
    };

    fetchProfile();
  }, [authTokens, logoutUser]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (!user && location.pathname !== '/login') {
    return (
      <Navigate
        to={`/login${location.search}`} // Preserve query params
        replace
        state={{
          // Store the current path in router's location.state
          from: location.pathname + location.search
        }}
      />
    );
  }

  return (
    <>
      <Header />
      <Routes>
        <Route
          path="/"
          element={
            <PrivateRoute>
              <HomePage>
              {profile?.is_teacher ? <TeacherHomePage /> : <StudentHomePage />}
              </HomePage>
            </PrivateRoute>
          }
        />
        <Route path="/login" element={<LoginPage />} />
        <Route
          path="/card-process/:cardId"
          element={
            <PrivateRoute>
              <HomePage>
              <ProcessPage />
              </HomePage>
            </PrivateRoute>
          }
        />
      </Routes>
    </>
  );
}

function App() {
  return (
    <div className="App">
      <Router>
        <AuthProvider>
          <AppRoutes />
        </AuthProvider>
      </Router>
    </div>
  );
}

export default App;