import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import AuthContext from '../context/AuthContext';
import ImpersonateModal from './ImpersonateModal';
import { Button } from './ui/button';
import { Badge } from './ui/badge';
import { AlertCircle, LogOut, UserPlus } from 'lucide-react';

const Header = () => {
  const { user, logoutUser, isImpersonating, endImpersonation } = useContext(AuthContext);

  return (
    <div className="flex items-center justify-between px-6 py-4 border-b bg-white shadow-sm">
      <div className="flex items-center gap-2">

      </div>

      <div className="flex items-center gap-4">
      <span className="text-xl font-semibold">PeerCards</span>
      <span className="text-sm text-muted-foreground">v.0.1.0</span>
        {isImpersonating && (
          <div className="flex items-center gap-4">
            <Badge variant="secondary" className="gap-1 py-2">
              <AlertCircle className="w-4 h-4" />
              <span>Impersonating: {user?.username}</span>
            </Badge>
            <Button
              variant="outline"
              size="sm"
              onClick={endImpersonation}
              className="gap-2 text-blue-600 hover:text-blue-700 hover:bg-blue-50"
            >
              <LogOut className="w-4 h-4" />
              Back to Admin Account
            </Button>
          </div>
        )}

        {user?.is_staff && !isImpersonating && (
          <Button
            variant="outline"
            size="sm"
            asChild
            className="gap-2"
          >
            <ImpersonateModal>
              <UserPlus className="w-4 h-4" />
              Impersonate User
            </ImpersonateModal>
          </Button>
        )}

        <Button
          variant="ghost"
          size="sm"
          onClick={logoutUser}
          className="gap-2 text-red-600 hover:text-red-700 hover:bg-red-50"
        >
          <LogOut className="w-4 h-4" />
          Logout
        </Button>
      </div>
    </div>
  );
};

export default Header;
