import React, { useState, useEffect, useContext } from 'react';
import { ScrollArea } from "../../components/ui/scroll-area";
import { Card, CardContent, CardHeader, CardTitle, CardDescription  } from "../../components/ui/card";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "../../components/ui/tabs";
import { getStudentCourses, getStudentAssignmentsWithCards, getStudentProfile } from '../../api/studentApi';
import AuthContext from '../../context/AuthContext';
import { Link } from 'react-router-dom';
import { getProfile } from '../../api/userApi';
import { motion } from 'framer-motion';
import { Badge, CheckCircle,
  BookOpen, 
  ClipboardList, 
  User, 
  Calendar,
  Clock,
  ArrowRight,
  GraduationCap,
  FileQuestion
 } from 'lucide-react';

const FlashCard = ({ card, assignmentMembers, isSubmitted }) => {
  return (
    <motion.div 
      className="card"
      whileHover={{ scale: 1.05 }}
      whileTap={{ scale: 0.95 }}
    >
      <Link to={`/card-process/${card.id}`}>
        <Card>
          <CardHeader>
            <CardTitle>{card.question.text}</CardTitle>
          </CardHeader>
          <CardContent>
            <p className="text-sm text-gray-500">
              Assignment members: {assignmentMembers.map((member) => member.username).join(', ')}
            </p>
            {isSubmitted && (
              <div className="flex items-center mt-2">
                
                <p className="text-sm text-gray-500 flex">
                  
                <Badge color="green" className="text-sm">
                
                </Badge>
                Sent to teacher

</p>

              </div>
            )}
          </CardContent>
        </Card>
      </Link>
    </motion.div>
  );
};

const AssignmentCard = ({ assignment }) => {
  const dueDate = new Date(assignment.due_date_first_answer);
  const isOverdue = dueDate < new Date();

  return (
    <Card className="hover:shadow-lg transition-shadow">
      <CardHeader>
        <div className="flex items-center justify-between">
          <CardTitle className="text-xl font-semibold">{assignment.name}</CardTitle>
          <Badge variant={isOverdue ? "destructive" : "secondary"}>
            <Clock className="w-4 h-4 mr-1" />
            {dueDate.toLocaleDateString()}
          </Badge>
        </div>
        <CardDescription>{assignment.description}</CardDescription>
      </CardHeader>
      <CardContent>
        <ScrollArea className="h-[250px] w-full rounded-md border p-4">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            {assignment.cards.map(card => (
              <Link 
                to={`/card-process/${card.id}`} 
                key={card.id} 
                className="block"
              >
                <Card className="hover:bg-accent transition-colors">
                  <CardContent className="p-4">
                    <div className="flex items-start justify-between">
                      <p className="font-medium">{card.question.text}</p>
                      {card.is_submitted && (
                        <CheckCircle className="w-5 h-5 text-green-500" />
                      )}
                    </div>
                    <div className="mt-2 flex items-center text-sm text-muted-foreground">
                      <span>Fase: {card.current_phase}</span>
                      <ArrowRight className="w-4 h-4 ml-2" />
                    </div>
                  </CardContent>
                </Card>
              </Link>
            ))}
          </div>
        </ScrollArea>
      </CardContent>
    </Card>
  );
};


const StudentHomePage = () => {
  const [courses, setCourses] = useState([]);
  const [assignments, setAssignments] = useState([]);

  const [profile, setProfile] = useState(null);
  const [loading, setLoading] = useState(true);
  const { authTokens, logoutUser } = useContext(AuthContext);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const profileData = await getProfile(authTokens.access);
        setProfile(profileData);
      } catch (error) {
        console.error('Error fetching data:', error);
        if (error.response && error.response.status === 401) {
          logoutUser();
          navigate('/login');
        }
      } 
    };

    fetchData();
  }, [authTokens, logoutUser]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (!authTokens) {
          console.error("Token non disponibile");
          logoutUser();
          return;
        }

        const [coursesData, assignmentsData, profileData] = await Promise.all([
          getStudentCourses(authTokens.access),
          getStudentAssignmentsWithCards(authTokens.access),
          getStudentProfile(authTokens.access)
        ]);
        setCourses(coursesData);
        setAssignments(assignmentsData);
        setProfile(profileData);
      } catch (error) {
        console.error("Errore nel caricamento dei dati:", error);
        if (error.response && error.response.status === 401) {
          logoutUser();
        }
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [authTokens, logoutUser]);

  if (loading) return <div>Loading...</div>;


  return (
    <motion.div 
      className="container mx-auto p-4"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      <h1 className="text-2xl font-bold mb-4">Welcome, {profile?.first_name}!</h1>
      
      <Tabs defaultValue="courses">
        <TabsList>
        <TabsTrigger value="courses">Courses</TabsTrigger>
          <TabsTrigger value="assignments">Assignments</TabsTrigger>
          <TabsTrigger value="profile">Profile</TabsTrigger>
        </TabsList>
        
        <TabsContent value="courses">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
            {courses.map(course => (
              <Card key={course.id}>
                <CardHeader>
                  <CardTitle>{course.name}</CardTitle>
                </CardHeader>
                <CardContent>
                  <p>{course.description}</p>
                </CardContent>
              </Card>
            ))}
          </div>
        </TabsContent>
        
        <TabsContent value="assignments" className="space-y-6">
      {assignments.length > 0 ? (
        assignments.map(assignment => (
          <AssignmentCard key={assignment.id} assignment={assignment} />
        ))
      ) : (
        <div className="flex flex-col items-center justify-center p-8 text-center border rounded-lg bg-gray-50">
          <FileQuestion className="w-12 h-12 text-gray-400 mb-3" />
          <h3 className="text-lg font-medium text-gray-900 mb-1">
            Nessun compito collaborativo presente
          </h3>
          <p className="text-sm text-gray-500">
            Al momento non ci sono compiti assegnati da visualizzare.
          </p>
        </div>
      )}
    </TabsContent>

    
        <TabsContent value="profile">
          <Card>
            <CardHeader>
              <CardTitle>Il tuo profilo</CardTitle>
            </CardHeader>
            <CardContent>
              <p>Nome: {profile?.first_name} {profile?.last_name}</p>
              <p>Email: {profile?.email}</p>
              <p>Ruolo: {profile?.is_teacher ? 'Insegnante' : 'Studente'}</p>
            </CardContent>
          </Card>
        </TabsContent>
      </Tabs>
    </motion.div>
  );
};

export default StudentHomePage;