const API_BASE_URL = process.env.REACT_APP_API_HOST;

export const getStudentCourses = async (token) => {
  const response = await fetch(`${API_BASE_URL}/api/student/courses/`, {
    method: 'GET',
    headers: {
      'Authorization': `Bearer ${token}`,
    },
  });
  if (!response.ok) throw new Error('Errore nel recupero dei corsi');
  return response.json();
};

export const getStudentAssignmentsWithCards = async (token) => {
  const response = await fetch(`${API_BASE_URL}/api/student/assignments-with-cards/`, {
    method: 'GET',
    headers: {
      'Authorization': `Bearer ${token}`,
    },
  });
  if (!response.ok) throw new Error('Errore nel recupero degli assignments e delle card');
  return response.json();
};
export const getStudentProfile = async (token) => {
  const response = await fetch(`${API_BASE_URL}/api/profile/`, {
    method: 'GET',
    headers: {
      'Authorization': `Bearer ${token}`,
    },
  });
  if (!response.ok) throw new Error('Errore nel recupero del profilo');
  return response.json();
};

/* Card API */

export const getCardStatus = async (token, cardId) => {
  const response = await fetch(`${API_BASE_URL}/api/student/card/${cardId}/status/`, {
    method: 'GET',
    headers: {
      'Authorization': `Bearer ${token}`,
    },
  });
  if (!response.ok) throw new Error('Errore nel recupero dello stato della card');
  return response.json();
};
export const getCardDetails = async (token, cardId) => {
  const response = await fetch(`${API_BASE_URL}/api/student/card/${cardId}/`, {
    method: 'GET',
    headers: {
      'Authorization': `Bearer ${token}`,
    },
  });
  if (!response.ok) throw new Error('Errore nel recupero dei dettagli della card');
  return response.json();
};

export const submitAnswer = async (token, cardId, answer) => {
  const response = await fetch(`${API_BASE_URL}/api/student/card/${cardId}/answer/`, {
    method: 'POST',
    headers: {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ text: answer }),
  });
  if (!response.ok) throw new Error('Errore nell\'invio della risposta');
  return response.json();
};

export const submitComment = async (token, answerId, text, formEvaluation, contentEvaluation) => {
  const response = await fetch(`${API_BASE_URL}/api/student/answer/${answerId}/comment/`, {
    method: 'POST',
    headers: {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      text,
      form_evaluation: formEvaluation,
      content_evaluation: contentEvaluation
    }),
  });
  if (!response.ok) throw new Error('Errore nell\'invio del commento');
  return response.json();
};

export const submitReview = async (token, cardId, review) => {
  const response = await fetch(`${API_BASE_URL}/api/student/card/${cardId}/review/`, {
    method: 'POST',
    headers: {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ text: review }),
  });
  if (!response.ok) throw new Error('Errore nell\'invio della revisione');
  return response.json();
};

export const submitCommentEvaluation = async (commentId, evaluation,token, cardId) => {
  const response = await fetch(`${API_BASE_URL}/api/student/card/${cardId}/comment-evaluation/${commentId}`, {
    method: 'POST',
    headers: {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ evaluation: evaluation })
  });
  
  if (response.status === 401) {
    throw new Error('Unauthorized');
  }
  
  if (!response.ok) {
    throw new Error('Error submitting comment evaluation');
  }
  
  const data = await response.json();
  return data;
};