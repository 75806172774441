const API_BASE_URL = process.env.REACT_APP_API_HOST 


export const getProfile = async (token) => {
    const response = await fetch(`${process.env.REACT_APP_API_HOST}/api/profile/`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        },
    });
    if (!response.ok) {
      
        throw new Error('Failed to fetch profile');
    }
    return response.json();
};

export const handleGoogleSignIn = async (response) => {
    
    try {
      const res = await fetch(`${process.env.REACT_APP_API_HOST}/api/google-login/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          from: 'frontend',
          ...response
          }),
      });
  
      if (res.ok) {
        const data = await res.json();
        return data;
      } else {
        // Login failed
        console.error('Login failed');
      }
    } catch (error) {
      console.error('Error during Google login:', error);
    }
};

export const impersonateUser = async (userId, token) => {
    try {
        const response = await fetch(`${process.env.REACT_APP_API_HOST}/api/impersonate/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
            body: JSON.stringify({
                user_id: userId
            }),
        });

        if (!response.ok) {
            throw new Error('Error during impersonification');
        }

        return response.json();
    } catch (error) {
        console.error('Errore during impersonification:', error);
        throw error;
    }
};
